import React from "react";
import styles from "@/styles/floatingCircles.module.scss";

const FloatingCircles = () => {
  return (
    <div className={styles.circles}>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
    </div>
  );
};

export default FloatingCircles;
