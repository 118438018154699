import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useRouter } from "next/router";
import { AnalyticsContext } from "@/utils/AnalyticsProvider";

const SignUpBanner = ({ instanceName }) => {
  const router = useRouter();
  const analytics = React.useContext(AnalyticsContext);

  const handleSignUpButtonClick = (e) => {
    e.preventDefault();
    analytics.action("sign_up_button_click", instanceName);
    router.push({ pathname: "/Subscribe", query: router.query });
  };

  return (
    <Grid container paddingTop={2} paddingLeft={2}>
      <Grid
        item
        xs={12}
        lg={12}
        alignContent={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Typography
          variant="h2"
          // component="h2"
          color="var(--accent2-dark)"
          padding={0}
          textAlign={"center"}
        >
          Sign up for free
        </Typography>
        <Typography
          variant="h5"
          // component="h6"
          color="var(--text-light)"
          padding={0}
          textAlign={"center"}
        >
          Sign up now, and get a 14-day free trial. Create your first motivation
          letter in less than 5 minutes.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          // size="large"
          sx={{
            marginTop: 2,
            marginX: "auto",
            width: "250px",
            height: "60px",
          }}
          // on click navigate to "Subscribe" page
          href="/Subscribe"
          onClick={handleSignUpButtonClick}
        >
          <Typography
            variant="caption"
            color="#ffffffe6"
            fontSize={20}
            fontWeight={900}
          >
            SIGN UP FOR FREE
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignUpBanner;
