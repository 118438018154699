import React from "react";
import styles from "@/styles/lines.module.scss";

const FallingLines = () => {
  return (
    <div className={styles.lines}>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
      <div className={styles.line}></div>
    </div>
  );
};

export default FallingLines;
