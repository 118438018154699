// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import { Link as RouterLink } from "react-router-dom";
import React from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
// import Script from "next/script";
import ContentBox from "@/components/Common/ContentBox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFlip } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import Image from "next/image";
import SignUpBanner from "@/components/Common/SignUpBanner";
import { useIsAuthenticated } from "@azure/msal-react";

// Import images
import happy1 from "../assets/happy1.png";
import happy2 from "../assets/happy2.png";
import happy3 from "../assets/happy3.png";
import happy4 from "../assets/happy4.png";
import happy5 from "../assets/happy5.png";
import happy6 from "../assets/happy6.png";
import happy7 from "../assets/happy7.png";
import step1 from "../assets/step1.png";
import step2 from "../assets/step2.png";
import step3 from "../assets/step3.png";
import step3svg from "../assets/step3.svg";
import { useEffect } from "react";
import { AnalyticsContext } from "@/utils/AnalyticsProvider";

// Dynamic imports
const SvgIcon = dynamic(() => import("@mui/material/SvgIcon"), {
  ssr: false,
});

const Dialog = dynamic(() => import("@mui/material/Dialog"), { ssr: false });

const DialogContent = dynamic(() => import("@mui/material/DialogContent"), {
  ssr: false,
});

const IconButton = dynamic(() => import("@mui/material/IconButton"), {
  ssr: false,
});

const CloseIcon = dynamic(() => import("@mui/icons-material/Close"), {
  ssr: false,
});

// const Button = dynamic(() => import("@mui/material/Button"), {
//   ssr: false,
// });

export default function Home() {
  const [openLetterDialog, setOpenLetterDialog] = React.useState(false);
  const [pageIdle, setPageIdle] = React.useState(false);
  const analytics = React.useContext(AnalyticsContext);
  const isAuthenticated = useIsAuthenticated();
  const handleOpenLetterDialog = () => {
    analytics.action("sample_letter_zoom_click");
    setOpenLetterDialog(true);
  };

  const handleCloseLetterDialog = () => {
    setOpenLetterDialog(false);
  };

  useEffect(() => {
    var timer = setTimeout(() => {
      setPageIdle(true);
    }, 2000);

    // cleanup
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Head>
        <title>Jaxen</title>
      </Head>
      <ContentBox
        backgroundColor={"var(--accent2-light)"}
        floatingCircles
        // fallingLines
      >
        <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
          <Grid item xs={12} md={6} xl={8} width={"100%"} alignContent={"left"}>
            <Typography
              variant="h2"
              component="h1"
              color="var(--accent2-dark)"
              gutterBottom
            >
              Land Your Dream Job in Minutes with Jaxen
            </Typography>
            <Typography variant="h4" component="h2" color="var(--text-light)">
              1-click Motivation Letter Generation for your job search.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} xl={4} paddingY={2}>
            <Box
              width={{ xs: "80vw", sm: "400px" }}
              height={{ xs: "80vw", sm: "400px" }}
              // width={"200px"}
              // height={"200px"}
              margin={{ xs: "auto", sm: "auto 0 0 auto" }}
              padding={0}
              // position={"relative"}
            >
              <Swiper
                // spaceBetween={50}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                effect="flip"
                loop={true}
                // flipEffect={{
                //   rotate: 30,
                //   slideShadows: false,
                // }}
                // pagination={{
                //   clickable: true,
                // }}
                // // navigation={true}
                modules={[Autoplay, EffectFlip]}
                // className="mySwiper"
                // width={"100%"}
                // height={"100%"}
                // onSwiper={(swiper) => console.log(swiper)}
                // onSlideChange={() => console.log("slide change")}
              >
                {/* {!DOMLoaded && ( */}
                {/* <SwiperSlide>
                  <img src="/images/happy1.png" alt="Happy Person" />
                </SwiperSlide> */}
                {/* )}
                {DOMLoaded && (
                  <> */}
                {/* <Suspense fallback={<></>}> */}
                {pageIdle && (
                  <>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy2}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy3}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy4}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy5}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy6}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide position="relative">
                      <Box
                        width={{ xs: "80vw", sm: "400px" }}
                        height={{ xs: "80vw", sm: "400px" }}
                        position="relative"
                      >
                        <Image
                          src={happy7}
                          alt="Happy Person"
                          placeholder="blur"
                          fill
                          sizes="(max-width: 768px) 80vw, 400px"
                        />
                      </Box>
                    </SwiperSlide>
                  </>
                )}
                <SwiperSlide>
                  <Box
                    width={{ xs: "80vw", sm: "400px" }}
                    height={{ xs: "80vw", sm: "400px" }}
                    position="relative"
                  >
                    <Image
                      src={happy1}
                      alt="Happy Person"
                      priority
                      placeholder="blur"
                      fill
                      sizes="(max-width: 768px) 80vw, 400px"
                    />
                  </Box>
                </SwiperSlide>
              </Swiper>
            </Box>
          </Grid>
        </Grid>
        {!isAuthenticated && <SignUpBanner instanceName="home_top" />}
      </ContentBox>
      <ContentBox paddingBottom={4}>
        <Grid
          container
          direction={"row"}
          // paddingTop={2}
          // paddingLeft={2}
        >
          <Grid item xs={12} md={4} alignContent={"center"} paddingTop={4}>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-light)"
            >
              Upto
            </Typography>
            <Typography
              variant="h6"
              fontSize={91}
              component="h6"
              textAlign={"center"}
              className="color-change"
              color="var(--text-black)"
              fontWeight={700}
            >
              16x
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-dark)"
              fontWeight={"700"}
            >
              More Interviews
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} alignContent={"center"} paddingTop={4}>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-light)"
            >
              Upto
            </Typography>
            <Typography
              variant="h6"
              fontSize={90}
              component="h6"
              textAlign={"center"}
              className="color-change"
              color="var(--text-black)"
              fontWeight={"bold"}
            >
              86%
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-dark)"
              fontWeight={"bold"}
            >
              Success Rate
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} alignContent={"center"} paddingTop={4}>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-light)"
            >
              Upto
            </Typography>
            <Typography
              variant="h6"
              fontSize={90}
              component="h6"
              textAlign={"center"}
              className="color-change"
              color="var(--text-black)"
              fontWeight={"bold"}
            >
              92%
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              textAlign={"center"}
              color="var(--text-dark)"
              fontWeight={"bold"}
            >
              Cost Savings
            </Typography>
          </Grid>
        </Grid>
      </ContentBox>
      <ContentBox backgroundColor={"var(--white-background-color)"}>
        <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
          <Grid item xs={12} width={"100%"} alignContent={"left"}>
            <Typography
              variant="h3"
              component="h6"
              color="var(--accent2-dark)"
              gutterBottom
            >
              No need to hire a professional resume writer or spend hours
              crafting a motivation letter from scratch. We can do it better and
              blazing fast!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} width={"100%"} alignContent={"left"}>
            <Box
              paddingY={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <svg
                width={"70px"}
                fill="var(--accent4)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M15.1986 9.94435C14.7649 9.53358 14.4859 8.98601 14.4085 8.39371L14.0056 5.31126L11.275 6.79711C10.7503 7.08262 10.1433 7.17876 9.55608 7.06936L6.49998 6.50003L7.06931 9.55612C7.17871 10.1434 7.08257 10.7503 6.79707 11.275L5.31121 14.0056L8.39367 14.4085C8.98596 14.4859 9.53353 14.7649 9.94431 15.1986L12.0821 17.4555L13.4178 14.6485C13.6745 14.1091 14.109 13.6745 14.6484 13.4179L17.4555 12.0821L15.1986 9.94435ZM15.2238 15.5078L13.0111 20.1579C12.8687 20.4572 12.5107 20.5843 12.2115 20.4419C12.1448 20.4102 12.0845 20.3664 12.0337 20.3127L8.49229 16.574C8.39749 16.4739 8.27113 16.4095 8.13445 16.3917L3.02816 15.7242C2.69958 15.6812 2.46804 15.3801 2.51099 15.0515C2.52056 14.9782 2.54359 14.9074 2.5789 14.8425L5.04031 10.3191C5.1062 10.198 5.12839 10.0579 5.10314 9.92241L4.16 4.85979C4.09931 4.53402 4.3142 4.22074 4.63997 4.16005C4.7126 4.14652 4.78711 4.14652 4.85974 4.16005L9.92237 5.10319C10.0579 5.12843 10.198 5.10625 10.319 5.04036L14.8424 2.57895C15.1335 2.42056 15.4979 2.52812 15.6562 2.81919C15.6916 2.88409 15.7146 2.95495 15.7241 3.02821L16.3916 8.13449C16.4095 8.27118 16.4739 8.39754 16.5739 8.49233L20.3127 12.0337C20.5533 12.2616 20.5636 12.6414 20.3357 12.8819C20.2849 12.9356 20.2246 12.9794 20.1579 13.0111L15.5078 15.2238C15.3833 15.2831 15.283 15.3833 15.2238 15.5078ZM16.0206 17.4349L17.4348 16.0207L21.6775 20.2633L20.2633 21.6775L16.0206 17.4349Z"></path>
              </svg>
            </Box>
            <Typography variant="h6" component="p" color="var(--text-light)">
              Jaxen specializes in generating dedicated motivation letters
              tailored to specific job posts with 1-click right from LinkedIn.
              The AI takes your resume, and some information about you, merged
              into the details from the job post and company details, and
              generates a motivation letter that will get you noticed.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} width={"100%"} alignContent={"left"}>
            <Box
              paddingY={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <svg
                width={"70px"}
                fill="var(--accent4)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M21 8V20.9932C21 21.5501 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8ZM19 9H14V4H5V20H19V9ZM8 7H11V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"></path>{" "}
              </svg>
            </Box>

            <Typography variant="h6" component="p" color="var(--text-light)">
              Just set up your account and start generating your motivation
              letters in minutes. Each letter is tailored to the specific job
              you are applying for, and targeted at the hiring company. They
              will read what they want to read, and you will look like the
              perfect candidate.
            </Typography>
          </Grid>
        </Grid>
      </ContentBox>
      <ContentBox>
        <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
          <Grid item xs={12} width={"100%"} alignContent={"left"}>
            <Typography
              variant="h3"
              component="h6"
              color="var(--accent2-dark)"
              // gutterBottom
            >
              How does it work?
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="var(--text-light)"
              gutterBottom
            >
              Generate outstanding motivation letters in 2 simple steps.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={5}
          paddingLeft={2}
          paddingTop={2}
          minHeight={"400px"}
        >
          <Grid item xs={12} md={6} alignContent={"center"}>
            <Box
              width={"100%"}
              // height={"400px"}
              // width={{ xs: "80vw", sm: "400px" }}
              height={{ xs: "80vw", sm: "400px", lg: "600px" }}
              position="relative"
              overflow="hidden"
              margin={"auto"}
            >
              <Image
                src={step1}
                alt="Step 1"
                position="absolute"
                placeholder="blur"
                fill
                loading="lazy"
                style={{
                  objectFit: "contain",
                  // border: "1px solid var(--accent2-dark)",
                }}
                sizes="(max-width: 600px) 80vw, (max-width: 1200px) 600px, 900px"
              />
            </Box>
            <Typography
              variant="h6"
              component="p"
              textAlign={"center"}
              color="var(--accent2-dark)"
              paddingTop={2}
            >
              Step 1: Create a template.
            </Typography>
            <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              color="var(--text-dark)"
            >
              Fill in a few details about yourself such as your objective and
              resume. You can have as many templates as you want, that can be
              targeted towards different jobs with a full set of details
              including a dedicated resume.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} alignContent={"center"}>
            <Box
              width={"100%"}
              // height={"400px"}
              // width={{ xs: "80vw", sm: "400px" }}
              height={{ xs: "80vw", sm: "400px", lg: "600px" }}
              position="relative"
              overflow="hidden"
              margin={"auto"}
            >
              <Image
                src={step2}
                alt="Step 2"
                position="absolute"
                placeholder="blur"
                fill
                loading="lazy"
                style={{
                  objectFit: "contain",
                  // border: "1px solid var(--accent2-dark)",
                }}
                sizes="(max-width: 600px) 80vw, (max-width: 1200px) 600px, 900px"
              />
            </Box>
            <Typography
              variant="h6"
              component="p"
              textAlign={"center"}
              color="var(--accent2-dark)"
              paddingTop={2}
            >
              Step 2: Go to your dream job on LinkedIn.
            </Typography>
            <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              color="var(--text-dark)"
            >
              Our chrome extension will add a new button to LinkedIn jobs page.
              Click "Create Motivation Letter" and watch the magic happen.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} alignContent={"center"}>
            <Box
              width={"100%"}
              // height={"400px"}
              // width={{ xs: "80vw", md: "30vw", lg: "400px" }}
              height={{ xs: "80vw", sm: "400px", lg: "600px" }}
              // height={{ xs: "80vw", md: "30vw", lg: "400px" }}
              position="relative"
              overflow="hidden"
              margin={"auto"}
            >
              <Image
                src={step3}
                alt="Step 3"
                position="absolute"
                placeholder="blur"
                // backgroundColor={"var(--white-background-color)"}
                fill
                loading="lazy"
                style={{ objectFit: "contain" }}
                // sizes="(max-width: 768px) 80vw, 600px"
                sizes="(max-width: 600px) 80vw, (max-width: 1200px) 600px, 900px"
              />
              <div className="zoom-button" onClick={handleOpenLetterDialog}>
                <SvgIcon>
                  <path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748ZM10 10V7H12V10H15V12H12V15H10V12H7V10H10Z"></path>
                </SvgIcon>
              </div>
            </Box>
            <Typography
              variant="h6"
              component="p"
              textAlign={"center"}
              color="var(--accent2-dark)"
              paddingTop={2}
            >
              Your motivation letter is ready!
            </Typography>
            <Typography
              variant="body1"
              component="p"
              textAlign={"center"}
              color="var(--text-dark)"
            >
              Your tailored motivation letter is ready to be sent. It will be
              automatically downloaded in DOCX format, so you can edit it if you
              want.
            </Typography>
          </Grid>
        </Grid>
      </ContentBox>
      {!isAuthenticated && (
        <ContentBox backgroundColor="var(--accent2-light)">
          <SignUpBanner instanceName="home_bottom" />
        </ContentBox>
      )}
      {/* <ContentBox>
        <Grid container paddingTop={2} paddingLeft={2}>
          <Grid item xs={12} lg={12} width={"100%"} alignContent={"left"}>
            <Typography
              variant="overline"
              component="p"
              color="var(--text-light)"
              padding={0}
            >
              1. Compared with traditional writing methods.
            </Typography>
            <Typography
              variant="overline"
              component="p"
              color="var(--text-light)"
              padding={0}
            >
              2. Compared with traditional process.
            </Typography>
            <Typography
              variant="overline"
              component="p"
              color="var(--text-light)"
              padding={0}
            >
              3. Compared with hiring a professional writer.
            </Typography>
          </Grid>
        </Grid>
      </ContentBox> */}

      {/* User Feedback */}
      {/* User Feedback */}
      {/* User Feedback */}
      {/* User Feedback */}
      {/* User Feedback */}
      {/* User Feedback */}
      {/* User Feedback */}
      {openLetterDialog && (
        <Dialog
          open={openLetterDialog}
          onClose={handleCloseLetterDialog}
          // fullScreen={fullScreen}
          fullScreen
          // maxHeight={"70vh"}
          // PaperProps={{
          //   sx: {
          //     maxHeight: { xs: "100%", sm: "70vh" },
          //   },
          // }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseLetterDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex: 100,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              // minWidth: { xs: 0, sm: "600px", md: "800px", lg: "1000px" },
              // minHeight: { xs: 0, sm: "400px" },
              width: "100%",
              height: "100%",
              padding: { xs: "0px", sm: "24px" },
            }}
          >
            <Box
              width={"100%"}
              height={"100%"}
              // height={"400px"}
              // width={{ xs: "80vw", md: "30vw", lg: "400px" }}
              // height={{ xs: "100vh", sm: "70vh" }}
              // height={{ xs: "80vw", md: "30vw", lg: "400px" }}
              position="relative"
              overflow="hidden"
              margin={"auto"}
            >
              <Image
                src={step3svg}
                alt="John Doe Motivation Letter"
                position="absolute"
                // placeholder="blur"
                backgroundColor={"var(--white-background-color)"}
                fill
                loading="lazy"
                style={{ objectFit: "contain" }}
                sizes="(max-width: 768px) 80vw, 400px"
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
