import React from "react";
import Box from "@mui/material/Box";
import FallingLines from "@/components/Common/FallingLines";
import FloatingCircles from "@/components/Common/FloatingCircles";

const ContentBox = ({
  children,
  fallingLines = false,
  floatingCircles = false,
  backgroundColor = "none",
  height = "auto",
  maxBodyWidth = "1000px",
  ...restProps
}) => {
  return (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={"center"}
      height={height}
      width={"100%"}
      // paddingX={2}
      // paddingY={2}
      // boxSizing={"border-box"}
      //   paddingX={`calc(50% - ${bodyWidth / 2}px)`}
      marginX={0}
      backgroundColor={backgroundColor}
      sx={{ position: "relative", zIndex: 1 }}
      {...restProps}
    >
      {fallingLines && <FallingLines />}
      {floatingCircles && <FloatingCircles />}
      <Box
        className="main-content"
        paddingX={2}
        paddingY={2}
        width={"100%"}
        maxWidth={maxBodyWidth}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentBox;
